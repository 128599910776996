<template>
  <div class="download-content">
    <div class="download-content-tab">
      <div class="downlaod-content-tab-box">
        <span class="app-log">下载中心</span>
        <span :class="['download-tab-item',{'active':$route.name==='downloadPc'}]"><router-link to="/download/pc">智慧课堂</router-link></span>
        <span :class="['download-tab-item',{'active':$route.name==='downloadPhone'}]"><router-link to="/download/phone">教师移动端</router-link></span>
      </div>
    </div>
    <div class="download-page">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// import api from '@api/api';
export default {
    name: 'download',
    data () {
        return {
            downloadUrl: '',
            host: 'https://youxueketang.anoah.com',
            // host: 'http://m5.anoah.com',
        };
    },
    mounted () {

    },
    methods: {
        getDownloadUrl (params) {
            return new Promise((resolve, reject) => {
                let xhr = new XMLHttpRequest();
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        let res = JSON.parse(xhr.responseText);
                        if (res.errcode === 0) {
                            resolve(res.data);
                        } else {
                            reject(new Error(res.errmsg));
                        }
                    } else if (xhr.readyState === 4 && xhr.status !== 200) {
                        reject(new Error('获取下载路径失败，请重试'));
                    }
                };
                xhr.open('GET', `${this.host}/api/operation/application/app/getLastVersion?packageName=${params.packageName}&publishType=1`, true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                // xhr.setRequestHeader('Authorization', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjA2Mzg4NjEsInRva2VuVHlwZSI6Imp3dCIsInVzZXJJZCI6MTQ4OH0.iajuKqnGrZP-_5hgXiKrwDDwyRJW41wT66gjLkflo4o');
                xhr.send();
            });
        },
        async download (packageName) {
            try {
                let downloadInfo = await this.getDownloadUrl({
                    packageName: packageName,
                });
                // console.log(this.convertUrl(downloadInfo.value.filePath));
                this.doDownload(this.convertUrl(downloadInfo.value.filePath));
            } catch (e) {
                this.$message.error(e.message);
            }
        },
        convertUrl (url) {
            let downloadUrl = url.replace(/https:\/\/yxp-cdn.anoah.com\/yxp-edu\/(\.*)/, '$1');
            downloadUrl = `https://youxueketang.anoah.com/file/download/${downloadUrl}?withOriginName=true`;
            return downloadUrl;
        },
        doDownload (url) {
            let a = document.createElement('a');
            let arr = url.split('/');
            a.download = arr[arr.length - 1];
            a.href = url;
            a.click();
            a.remove();
        },
    },
    destroyed () {

    },
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  .download-content{
    height: 100%;
    min-height: 800px;
    min-width: 1366px;
    &-tab{
        height: 50px;
        .downlaod-content-tab-box{
          width: 1366px;
          margin: 0 auto;
          height: 100%;
          line-height: 50px;
          color: #333333;
          padding: 0 60px;
          .app-log{
            font-size: 20px;
            color: #333333;
            margin-right: 80px;
            &::before{
              content: '';
              display: inline-block;
              width: 30px;
              height: 30px;
              background: url(./images/icon-title.png) no-repeat center center;
              vertical-align: middle;
              margin-top: -5px;
              margin-right: 7px;
            }
          }
          .download-tab-item{
            font-size: 16px;
            position: relative;
            margin-right: 42px;
            a{
              color: #333;
            }
            &::after{
              content: '';
              display: inline-block;
              position: absolute;
              bottom: -6px;
              left: 0;
              width: 37px;
              height: 4px;
              border-radius: 3px;
              background: transparent;
              left: calc(50% - 18px);
            }
            &.active{
              font-size: 20px;
              color: #3D9AFF;
              font-weight: 600;
              a{
                color: #3D9AFF;
              }
              &::after{
                background:#3D9AFF;
              }
            }
          }
        }
    }
    .download-page{
      height: calc(100% - 50px);
    }
      ::v-deep .captcha-dialog {
          .el-dialog__body {
              padding-top: 10px;
              padding-bottom: 12px;
              .captcha-div {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 12px;
                  .change-one {
                      color: #409EFF;
                      cursor: pointer;
                      font-size: 16px;
                      user-select: none;
                      &:hover {
                          color: #66b1ff;
                      }
                  }
              }
              .el-input.is-active .el-input__inner, .el-input__inner:focus {
                  border-color: #409EFF;
                  outline: 0;
              }
          }
          .dialog-footer {
              .el-button:active {
                  color: #3a8ee6;
                  border-color: #3a8ee6;
                  outline: 0;
              }
              .el-button:focus, .el-button:hover {
                  color: #409EFF;
                  border-color: #c6e2ff;
                  background-color: #ecf5ff;
              }
              .el-button--primary:focus, .el-button--primary:hover {
                  background: #66b1ff;
                  border-color: #66b1ff;
                  color: #FFF;
              }
              .el-button--primary {
                  color: #FFF;
                  background-color: #409EFF;
                  border-color: #409EFF;
              }
          }
      }
  }
  @media screen and (max-width: 1024px){
    .download-content{
      min-width: 1024px;
        .downlaod-content-tab-box{
            width: 1024px;
        }
    }
}
</style>
<style lang="scss" rel="stylesheet/scss" >
  @import "@style/variable.scss";
  #yxp_website.download{
    padding-top: 0;
    .header-out,.footer-out{
      display: none;
    }
    .content-out{
      padding-bottom: 0;
      height: 100%;
    }
  }

</style>
