export default {
    data () {
        return {
            downloadUrl: '',
            // host: 'http://m5.anoah.com',
            showCaptcha: false,
            uuid: '',
            captchaUrl: '',
            captchaCode: '',
            packageName: '',
        };
    },
    computed: {
        host () {
            if (['development', 'test'].includes(window.yxpapiConfig.env)) {
                return 'https://edu-test1.anoah.com';
            }
            return window.location.origin;
        },
    },
    watch: {
        showCaptcha (val) {
            if (val) {
                this.getCaptcha();
            }
        },
    },
    methods: {
        getDownloadUrl () {
            return new Promise((resolve, reject) => {
                let xhr = new XMLHttpRequest();
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        let res = JSON.parse(xhr.responseText);
                        if (res.errcode === 0) {
                            resolve(res.data);
                        } else {
                            this.$message.error(res.errmsg);
                            reject(new Error(res.errmsg));
                        }
                    } else if (xhr.readyState === 4 && xhr.status !== 200) {
                        this.$message.error('获取下载路径失败，请重试');
                        reject(new Error('获取下载路径失败，请重试'));
                    }
                };
                xhr.open('POST', `${this.host}/api/operation/application/app/getLastVersionByCode`, true);
                xhr.setRequestHeader('Content-type', 'application/json');
                // xhr.setRequestHeader('Authorization', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjA2Mzg4NjEsInRva2VuVHlwZSI6Imp3dCIsInVzZXJJZCI6MTQ4OH0.iajuKqnGrZP-_5hgXiKrwDDwyRJW41wT66gjLkflo4o');
                xhr.send(JSON.stringify({
                    packageName: this.packageName,
                    publishType: 1,
                    uuid: this.uuid,
                    code: this.captchaCode,
                }));
            });
        },
        async download (packageName) {
            this.packageName = packageName;
            this.showCaptcha = true;
        },
        convertUrl (url) {
            let downloadUrl;
            if (window.yxpapiConfig.env === 'production') {
                downloadUrl = url.replace(/https:\/\/yxp-cdn.anoah.com\/yxp-edu\/(\.*)/, '$1');
                downloadUrl = `${this.host}/file/download/${downloadUrl}?withOriginName=true`;
            } else {
                downloadUrl = url + '?withOriginName=true';
            }
            return downloadUrl;
        },
        doDownload (url) {
            let a = document.createElement('a');
            let arr = url.split('/');
            a.download = arr[arr.length - 1];
            a.href = url;
            a.click();
            a.remove();
        },
        getCaptcha () {
            this.uuid = this.$randomId(30);
            this.captchaUrl = `${this.host}/api/operation/captcha/getCode?uuid=${this.uuid}&width=150&height=50`;
        },
        onCaptchaSure () {
            try {
                if (!this.captchaCode) {
                    return this.$message.error('请输入验证码');
                }
                this.getDownloadUrl().then(res => {
                    this.showCaptcha = false;
                    this.captchaUrl = '';
                    this.captchaCode = '';
                    window.yxpapi.buriedPoint({
                        method: 'recordIgnoreUser',
                        module: '076',
                        code: this.packageName === 'iclass' ? '001' : '002',
                    });
                    this.doDownload(this.convertUrl(res.value.filePath));
                });
            } catch (e) {
                this.$message.error(e.message);
            }
        },
        /**
         * 随机生成id
         * @param n 默认32位
         * @returns {string}
         */
        $randomId (n = 32) {
            let s = '';
            Array.from({ length: n }).forEach((v, i) => {
                let r = Math.floor(Math.random() * 36);
                if (r < 10) {
                    s += r;
                } else {
                    s += String.fromCharCode(r + 87);
                }
                if ([7, 11, 15, 19].includes(i)) s += '-';
            });
            return s;
        },
    },
};
